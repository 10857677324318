import React from 'react';
import ReactPaginate from 'react-paginate';
import AddNewWordModal from "../Modals/AddNewWordModal";
import AddNewWordToSelectedLanguage from "../Modals/AddNewWordToSelectedLanguage";
import UpdateWordWithSelectedAttributeInSelectedLanguageModal from "../Modals/UpdateWordWithSelectedAttributeInSelectedLanguage";

export function DisplayNonEnglishViewTable(
    displayLanguage,
    displayedEntries,
    handleShowDeletePopUp,
    totalPages,
    handlePageChange,
    currentPage,
    handleShowUpdateWordWithSelectedAttributeInSelectedLanguage,
    showUpdateWordWithSelectedAttributeInSelectedLanguage,
    handleHideUpdateWordWithSelectedAttributeInSelectedLanguage,
    updatedAttributeLanguage,
    selectedAttribute,
    currentEnglishWord,
    RefreshViewTable,
    newWordId,
    selectedLanguage,
    originalWord
) {
    return (
        <div className="dictionary-entries">
            {displayLanguage &&<h2>Dictionary Entries for {displayLanguage}</h2>}
            {showUpdateWordWithSelectedAttributeInSelectedLanguage && (
                <UpdateWordWithSelectedAttributeInSelectedLanguageModal
                    disabled={showUpdateWordWithSelectedAttributeInSelectedLanguage}
                    updatedAttributeLanguage={updatedAttributeLanguage}
                    selectedAttribute={selectedAttribute}
                    RefreshViewTable={RefreshViewTable}
                    onCancel={handleHideUpdateWordWithSelectedAttributeInSelectedLanguage}
                    newWordId={newWordId}
                    currentEnglishWord={currentEnglishWord}
                    originalWord={originalWord}
                />
            )}
            {displayLanguage && <table className="dictionary-table">
                <thead>
                <tr>
                    <th>Delete</th>
                    <th>{displayLanguage} Word</th>
                    <th>English Translation</th>
                    <th>Word Class</th>
                    <th>Pronunciation</th>
                    <th>Synonym</th>
                    <th>Usage Sentence</th>
                </tr>
                </thead>
                <tbody>
                {displayedEntries.map((entry, index) => (
                    <tr key={index}>
                        <td style={{textAlign: "center"}}>
                            <button onClick={() => handleShowDeletePopUp(entry.word_id, "current", entry.translated_word)}>Delete</button>
                        </td>
                        <td>{entry.translated_word}</td>
                        <td>{entry.english_word} </td>
                        <td style={{ textAlign: "center" }}>
                            {entry.word_class? entry.word_class : <button onClick={() => handleShowUpdateWordWithSelectedAttributeInSelectedLanguage(selectedLanguage, entry.word_id, "word_class", entry.english_word, entry.translated_word)}>Add</button>}
                        </td>
                        <td style={{ textAlign: "center" }}>
                            {entry.pronunciation? entry.pronunciation : <button onClick={() => handleShowUpdateWordWithSelectedAttributeInSelectedLanguage(selectedLanguage, entry.word_id, "pronunciation", entry.english_word, entry.translated_word)}>Add</button>}
                        </td>
                        <td style={{ textAlign: "center" }}>
                            {entry.synonym? entry.synonym : <button onClick={() => handleShowUpdateWordWithSelectedAttributeInSelectedLanguage(selectedLanguage, entry.word_id, "synonym", entry.english_word, entry.translated_word)}>Add</button>}
                        </td>
                        <td style={{ textAlign: "center" }}>
                            {entry.usage_sentence? entry.usage_sentence : <button onClick={() => handleShowUpdateWordWithSelectedAttributeInSelectedLanguage(selectedLanguage, entry.word_id, "usage_sentence", entry.english_word, entry.translated_word)}>Add</button>}
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>}
            {displayLanguage && <ReactPaginate
                pageCount={totalPages}
                onPageChange={handlePageChange}
                forcePage={currentPage - 1}
                containerClassName={'pagination'}
                activeClassName={'active'}
            />}
        </div>
    );
}

export function DisplayEnglishViewTable(
    displayLanguage,
    selectedLanguage,
    RefreshViewTable,
    displayedEntries,
    handleShowDeletePopUp,
    setNewEnglishWord,
    newEnglishWord,
    handleNewEnglishWord,
    totalPages,
    handlePageChange,
    currentPage,
    showAddNewWordModal,
    handleShowAddNewWordModal,
    handleHideAddNewWordModal,
    handleShowAddNewWordModalGivenSelectedLanguage,
    showAddNewWordModalGivenSelectedLanguage,
    newWordLanguage,
    handleHideAddNewWordModalGivenSelectedLanguage,
    newWordId,
    currentEnglishWord,
) {
    return (
        <div className="dictionary-entries">
            <h2>Available Translations</h2>
            <button onClick={handleShowAddNewWordModal}>Add New Word</button>

            {showAddNewWordModal && (
                <AddNewWordModal
                    disabled={showAddNewWordModal}
                    selectedLanguage={selectedLanguage}
                    RefreshViewTable={RefreshViewTable}
                    onCancel={handleHideAddNewWordModal}
                />
            )}

            {showAddNewWordModalGivenSelectedLanguage && (
                <AddNewWordToSelectedLanguage
                    disabled={showAddNewWordModalGivenSelectedLanguage}
                    newWordLanguage={newWordLanguage}
                    RefreshViewTable={RefreshViewTable}
                    onCancel={handleHideAddNewWordModalGivenSelectedLanguage}
                    newWordId={newWordId}
                    currentEnglishWord={currentEnglishWord}
                />
            )}
            <table className="dictionary-table">
                <thead>
                <tr>
                    <th>Delete</th>
                    <th>English Word</th>
                    <th>Hindi</th>
                    <th>Urdu</th>
                    <th>Bengali</th>
                    <th>Persian</th>
                    <th>Punjabi</th>
                    <th>Tamil</th>
                </tr>
                </thead>
                <tbody>
                {displayedEntries.map((entry, index) => (
                    <tr key={index}>
                        <td style={{textAlign: "center"}}>
                            <button onClick={() => handleShowDeletePopUp(entry.word_id, "all", entry.english_word)}>Delete</button>
                        </td>
                        <td style={{ textAlign: "center" }}>
                            {entry.english_word ? entry.english_word :
                            <input value={newEnglishWord} type="text" onChange={handleNewEnglishWord}></input>}</td>
                        <td style={{ textAlign: "center" }}>
                            {entry.hindi_word? entry.hindi_word : <button onClick={() => handleShowAddNewWordModalGivenSelectedLanguage("HIN", entry.word_id, entry.english_word)}>Add Translation</button>}
                        </td>
                        <td style={{ textAlign: "center" }}>
                            {entry.urdu_word? entry.urdu_word : <button onClick={() => handleShowAddNewWordModalGivenSelectedLanguage("URD", entry.word_id, entry.english_word)}>Add Translation</button>}
                        </td>
                        <td style={{ textAlign: "center" }}>
                            {entry.bengali_word? entry.bengali_word : <button onClick={() => handleShowAddNewWordModalGivenSelectedLanguage("BEN", entry.word_id, entry.english_word)}>Add Translation</button>}
                        </td>
                        <td style={{ textAlign: "center" }}>
                            {entry.persian_word? entry.persian_word : <button onClick={() => handleShowAddNewWordModalGivenSelectedLanguage("PER", entry.word_id, entry.english_word)}>Add Translation</button>}
                        </td>
                        <td style={{ textAlign: "center" }}>
                            {entry.punjabi_word? entry.punjabi_word : <button onClick={() => handleShowAddNewWordModalGivenSelectedLanguage("PAN", entry.word_id, entry.english_word)}>Add Translation</button>}
                        </td>
                        <td style={{ textAlign: "center" }}>
                            {entry.tamil_word? entry.tamil_word : <button onClick={() => handleShowAddNewWordModalGivenSelectedLanguage("TAM", entry.word_id, entry.english_word)}>Add Translation</button>}
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            {selectedLanguage !== '' && <ReactPaginate
                pageCount={totalPages}
                onPageChange={handlePageChange}
                forcePage={currentPage - 1}
                containerClassName={'pagination'}
                activeClassName={'active'}
            />}
        </div>
    );
}
