import React, {useState} from 'react';
import './LanguageSelector.css'; // Import the CSS file

function LanguageSelector({onSelectLanguage, isEnglishOptionVisible}) {
    const [selectedLang, setSelectedLang] = useState('');

    const handleLanguageChange = (event) => {
        const lang = event.target.value;
        setSelectedLang(lang);
        onSelectLanguage(lang); // Notify parent component about the selected language
    };
    return (
        <div className="language-selector-container">
            <label htmlFor="selectLang">Select Language: </label>
            <select id="selectLang"  onChange={handleLanguageChange}>
                <option value="" disabled selected>Select...</option>
                {isEnglishOptionVisible && <option value="ENG">English Overview</option>}
                {isEnglishOptionVisible && <option value="" disabled>--</option>}
                <option value="BEN">Bengali</option>
                <option value="HIN">Hindi</option>
                <option value="PER">Persian</option>
                <option value="PAN">Punjabi</option>
                <option value="TAM">Tamil</option>
                <option value="URD">Urdu</option>
            </select>
        </div>
    );
}

export default LanguageSelector;
