import React, { useEffect } from "react";
import "./Title.css"
export default function App() {
  useEffect(() => {
    document.title = "South Asian Multilingual Dictionary";
  }, []);

  return (
    <div className="App">
      <h1 className="DocTitle">South Asian Multilingual Dictionary</h1>
    </div>
  );
}