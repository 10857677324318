// AdminHomepage.js
import React from 'react';
import './AdminHomepage.css';
import { useNavigate } from "react-router-dom";

const AdminHomepage = () => {
    const navigate = useNavigate();
    return (
        <div className="admin-homepage">
            <h1>Welcome to the Admin Homepage</h1>
            <div className="button-list">
                <button className="view-button" onClick={() => navigate("/adminpage/view")}>View Dictionary</button>
                <button className="add-button" onClick={() => navigate("/adminpage/add")}>Add to Dictionary</button>
                <button className="delete-button">Delete Word</button>
            </div>
        </div>
    );
};

export default AdminHomepage;
