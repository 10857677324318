import React, { useEffect, useState } from 'react';
import './AdminViewDictionary.css'; // Link to the CSS file
import LanguageSelector from '../../../Components/LanguageSelection/LanguageSelector';
import DeletePopUp from '../../../Components/Modals/DeletePopUp';
import axios from "axios";
import {
    DictionaryResult,
    EnglishWordResult,
} from "../DataClass/Data";
import {
    DisplayNonEnglishViewTable,
    DisplayEnglishViewTable,
} from "../../../Components/AdminPortal/DisplayViewTables";
import { displaySelectedLanguageAsWholeName } from "../../../Components/LanguageSelection/DisplaySelectedLanguageAsWholeName";

function AdminViewDictionary() {
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [displayLanguage, setDisplayLanguage] = useState('');
    const [dictionaryEntries, setDictionaryEntries] = useState([]);
    const [newEnglishWord, setNewEnglishWord] = useState("");
    const [showDeletePopUp, setShowDeletePopUp] = useState(false);
    const [wordIdToDelete, setWordIdToDelete] = useState('');
    const [wordToDelete, setWordToDelete] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, ] = useState(7);
    const [totalPages, setTotalPages] = useState(1);
    const [showAddNewWordModal, setShowAddNewWordModal] = useState(false);
    const [showAddNewWordModalGivenSelectedLanguage, setShowAddNewWordModalGivenSelectedLanguage] = useState(false);
    const [showUpdateWordWithSelectedAttributeInSelectedLanguage, setShowUpdateWordWithSelectedAttributeInSelectedLanguage] = useState(false);
    const [newWordLanguage, setNewWordLanguage] = useState('');
    const [originalWord, setOriginalWord] = useState('');
    const [newWordId, setNewWordId] = useState();
    const [currentEnglishWord, setCurrentEnglishWord] = useState('');
    const [selectedAttribute, setSelectedAttribute] = useState('');
    const [deleteAllOrCurrentRecord, setDeleteAllOrCurrentRecord] = useState('');
    const [updatedAttributeLanguage, setUpdatedAttributeLanguage] = useState('');
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const displayedEntries = dictionaryEntries.slice(startIndex, endIndex);
    const isEnglishOptionVisible = true;

    const handleShowDeletePopUp = (word_id, deleteAllOrCurrentRecord, wordToDelete) => {
        setWordToDelete(wordToDelete);
        setDeleteAllOrCurrentRecord(deleteAllOrCurrentRecord);
        setWordIdToDelete(word_id);
        setShowDeletePopUp(true);
    };

    const handleHideDeletePopUp = () => {
        setWordIdToDelete('');
        setShowDeletePopUp(false);
    };

    const handleConfirmDelete = (wordId) => {
        if (deleteAllOrCurrentRecord === 'current') {
            axios
                .delete(
                    `https://southasiandictionary.asian.lsa.umich.edu/api/translator?selectedLanguage=${selectedLanguage}&searchedWordId=${wordId}`
                )
                .then(() => {
                    RefreshViewTable();
                })
                .catch((error) => {
                    console.error('Failed to delete record:', error);
                });
        }
        else {
            axios
                .delete(
                    `https://southasiandictionary.asian.lsa.umich.edu/api/deleteWordFromAllTables?searchedWordId=${wordId}`
                )
                .then(() => {
                    RefreshViewTable();
                })
                .catch((error) => {
                    console.error('Failed to delete record:', error);
                });
        }
        handleHideDeletePopUp();
    };

    const handleSelectLanguage = (lang) => {
        setSelectedLanguage(lang);
    };

    function RefreshViewTable() {
        const apiUrl = `https://southasiandictionary.asian.lsa.umich.edu/api/view?selectedLanguage=${selectedLanguage}`;
        fetch(apiUrl, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                const dictionaryEntries = data.map((entry) =>
                    selectedLanguage !== 'ENG'
                        ? new DictionaryResult(
                            entry.word_id,
                            entry.translated_word,
                            entry.word_class,
                            entry.pronunciation,
                            entry.synonym,
                            entry.usage_sentence,
                            entry.audio_file,
                            entry.english_word
                        )
                        : new EnglishWordResult(
                            entry.word_id,
                            entry.english_word,
                            entry.bengali_word,
                            entry.hindi_word,
                            entry.persian_word,
                            entry.punjabi_word,
                            entry.tamil_word,
                            entry.urdu_word
                        )
                );
                setDictionaryEntries(dictionaryEntries);
                setTotalPages(Math.ceil(dictionaryEntries.length / pageSize));
                setCurrentPage(1);
            })
            .catch((error) => {
                console.log('error: ' + error);
            });
    }

    useEffect(() => {
        if (selectedLanguage) {
            RefreshViewTable();
        }
        displaySelectedLanguageAsWholeName(
            selectedLanguage,
            setDisplayLanguage
        );
    }, [selectedLanguage]);

    const handleShowAddNewWordModal = () => {
        setShowAddNewWordModal(true);
    };

    const handleShowAddNewWordModalGivenSelectedLanguage = (lang, word_id, englishWord) => {
        setCurrentEnglishWord(englishWord)
        setNewWordLanguage(lang)
        setNewWordId(word_id);
        setShowAddNewWordModalGivenSelectedLanguage(true);
    };

    const handleHideAddNewWordModalGivenSelectedLanguage = () => {
        setNewWordLanguage('');
        setNewWordId(null);
        setShowAddNewWordModalGivenSelectedLanguage(false);
    };

    const handleShowUpdateWordWithSelectedAttributeInSelectedLanguage = (lang, word_id, attribute, english_word, original_word) => {
        setOriginalWord(original_word)
        setCurrentEnglishWord(english_word)
        setSelectedAttribute(attribute)
        setUpdatedAttributeLanguage(lang)
        setNewWordId(word_id);
        setShowUpdateWordWithSelectedAttributeInSelectedLanguage(true);
    };

    const handleHideUpdateWordWithSelectedAttributeInSelectedLanguage = () => {
        setCurrentEnglishWord('')
        setSelectedAttribute('')
        setUpdatedAttributeLanguage('')
        setNewWordId(0);
        setShowUpdateWordWithSelectedAttributeInSelectedLanguage(false);
    };

    const handleHideAddNewWordModal = () => {
        setShowAddNewWordModal(false);
    };

    function handleNewEnglishWord(e) {
        setNewEnglishWord(e.target.value);
    }

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage.selected + 1);
    };

    return (
        <div className="dictionary-container">
            <h1 className="dictionary-title">Dictionary Viewer</h1>
            <LanguageSelector onSelectLanguage={handleSelectLanguage} isEnglishOptionVisible={isEnglishOptionVisible} />
            {selectedLanguage !== 'ENG' &&
                DisplayNonEnglishViewTable(
                    displayLanguage,
                    displayedEntries,
                    handleShowDeletePopUp,
                    totalPages,
                    handlePageChange,
                    currentPage,
                    handleShowUpdateWordWithSelectedAttributeInSelectedLanguage,
                    showUpdateWordWithSelectedAttributeInSelectedLanguage,
                    handleHideUpdateWordWithSelectedAttributeInSelectedLanguage,
                    updatedAttributeLanguage,
                    selectedAttribute,
                    currentEnglishWord,
                    RefreshViewTable,
                    newWordId,
                    selectedLanguage,
                    originalWord
                )}
            {selectedLanguage === 'ENG' &&
                DisplayEnglishViewTable(
                    displayLanguage,
                    selectedLanguage,
                    RefreshViewTable,
                    displayedEntries,
                    handleShowDeletePopUp,
                    setNewEnglishWord,
                    newEnglishWord,
                    handleNewEnglishWord,
                    totalPages,
                    handlePageChange,
                    currentPage,
                    showAddNewWordModal,
                    handleShowAddNewWordModal,
                    handleHideAddNewWordModal,
                    handleShowAddNewWordModalGivenSelectedLanguage,
                    showAddNewWordModalGivenSelectedLanguage,
                    newWordLanguage,
                    handleHideAddNewWordModalGivenSelectedLanguage,
                    newWordId,
                    currentEnglishWord,
                )}
            <DeletePopUp
                show={showDeletePopUp}
                word={wordToDelete}
                onCancel={handleHideDeletePopUp}
                onDelete={() => handleConfirmDelete(wordIdToDelete)}
                allOrCurrentRecord={deleteAllOrCurrentRecord}
                selectedLanguage={selectedLanguage}
            />
        </div>
    );
}

export default AdminViewDictionary;
