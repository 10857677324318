import React from 'react';
import './SplitScreen.css'; // Import CSS for styling

const SplitScreen = ({ topContent, leftContent, rightContent }) => {
    return (
        <div className="split-screen">
            <div className="top-panel">{topContent}</div>
            <div className="bottom-panels">
                <div className="left-panel">{leftContent}</div>
                <div className="right-panel">{rightContent}</div>
            </div>
        </div>
    );
};
export default SplitScreen;
