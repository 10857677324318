import React, { useState, useEffect } from 'react';
import './TranslationResultComponent.css'; // Import the default CSS file

/**/
function isRTLLanguage(languageCode) {
    // Define a list of RTL language codes (e.g., 'ar' for Arabic, 'he' for Hebrew)
    const rtlLanguages = ['URD', 'PER']; // Add more RTL languages here

    // Check if the language code is in the RTL languages list
    return rtlLanguages.includes(languageCode);
}

class TranslatorResult {
    constructor(translated_word, word_class, pronunciation, synonym, usage_sentence) {
        this.translated_word = translated_word;
        this.word_class = word_class;
        this.pronunciation = pronunciation;
        this.synonym = synonym;
        this.usage_sentence = usage_sentence;
    }
}

function TranslationResult({ selectedLanguage, searchedWord }) {
    const [translatorResult, setTranslatorResult] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Reset translatorResult when the component unmounts or user navigates away
        const handleUnload = () => {
            setTranslatorResult(null);
        };

        window.addEventListener('beforeunload', handleUnload);

        return () => {
            handleUnload();
            window.removeEventListener('beforeunload', handleUnload);
        };
    }, []);

    useEffect(() => {
        setTranslatorResult(null);

        if (selectedLanguage && searchedWord) {
            const apiUrl = `https://southasiandictionary.asian.lsa.umich.edu/api/translator?selectedLanguage=${selectedLanguage}&searchedWord=${searchedWord}`;

            fetch(apiUrl, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json; charset=utf-8'
                }
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    const translatorResult = new TranslatorResult(
                        data.translated_word,
                        data.word_class,
                        data.pronunciation,
                        data.synonym,
                        data.usage_sentence
                    );
                    console.log(translatorResult);
                    setTranslatorResult(translatorResult);
                })
                .catch(error => {
                    setError(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [selectedLanguage, searchedWord]);

    useEffect(() => {
        // Load translatorResult from sessionStorage on component mount
        const savedTranslatorResult = sessionStorage.getItem('translatorResult');
        if (savedTranslatorResult) {
            setTranslatorResult(JSON.parse(savedTranslatorResult));
        }
    }, []);

    return (
        <div className='translation-result-container'>
            {translatorResult && (
                <div className='translation-result-interior-container'>
                    <p className='translated-word-label'>Translated Word: <br/></p>
                    <p dir={isRTLLanguage(selectedLanguage) ? 'rtl' : 'ltr'} className='translated-result'> {translatorResult.translated_word}</p>

                    <p className='word-class-label'>Word Class: <br/></p>
                    <p dir={isRTLLanguage(selectedLanguage) ? 'rtl' : 'ltr'} className='translated-result'> {translatorResult.word_class}</p>

                    <p className='pronunciation-label'>Pronunciation: <br/></p>
                    <p dir={isRTLLanguage(selectedLanguage) ? 'rtl' : 'ltr'} className='translated-result'> {translatorResult.pronunciation}</p>

                    <p className='synonym-label'>Synonym: <br/></p>
                    <p dir={isRTLLanguage(selectedLanguage) ? 'rtl' : 'ltr'} className='translated-result'> {translatorResult.synonym}</p>

                    <p className='usage-sentence-label'>Usage Sentence: <br/></p>
                    <p dir={isRTLLanguage(selectedLanguage) ? 'rtl' : 'ltr'} className='translated-result'> {translatorResult.usage_sentence}</p>

                    {/* Display audio file if needed */}
                </div>
            )}
        </div>
    );
}

export default TranslationResult;
