import React from 'react';
import './DeletePopUp.css';

function displaySelectedLanguageAsWholeName(selectedLanguage) {
  switch (selectedLanguage) {
    case "BEN":
      return "Bengali"
    case "HIN":
      return "Hindi";
    case "URD":
      return "Urdu";
    case "PER":
      return "Persian";
    case "PAN":
      return "Punjabi";
    case "TAM":
      return "Tamil";
    default:
      return ""
  }
}

function DeleteCurrentRecordPopUp({ show, word, onCancel, onDelete, allOrCurrentRecord, selectedLanguage}) {
  return (
    <div className={`delete-popup ${show ? 'show' : ''}`}>
      <div className="popup-content">
        <h3>Confirm Deletion</h3>
        {allOrCurrentRecord === "all" ? <p>Are you sure you want to delete {word} <strong><u>from every table</u></strong>?</p> : <p>Are you sure you want to delete {word} from the <strong><u>{displaySelectedLanguageAsWholeName(selectedLanguage)} table</u></strong>?</p>}
        <div className="button-container">
          <button className="cancel-button" onClick={onCancel}>Cancel</button>
          <button className="delete-button" onClick={onDelete}>Delete</button>
        </div>
      </div>
    </div>
  );
}

export default DeleteCurrentRecordPopUp;