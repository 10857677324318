import React, { useState, useEffect } from 'react';
import LanguageSelector from './Components/LanguageSelection/LanguageSelector';
import SearchWordComponent from './Components/WordSearch/SearchWordComponent';
import TranslationResultComponent from "./Components/Translation/TranslationResultComponent";
import AdminLoginButton from "./Components/AdminLogin/AdminLoginButton";
import NavigationBar from "./Components/NavigationBar/NavigationBar";
import Title from './Components/Title/Title';
import SplitScreen from './Components/SplitScreen/SplitScreen';
import AboutUs from "./Pages/AboutUs/About";
import Home from "./Pages/Home/Home";
import AdminHomepage from './Pages/Admin/AdminHomepage/AdminHomepage';
import AdminEditPage from './Pages/Admin/AdminEdit/AdminEditPage';
import AdminAdd from './Pages/Admin/AdminAdd/AdminAdd';
import AdminViewDictionary from './Pages/Admin/AdminViewDictionary/AdminViewDictionary';
import Login from './Pages/LoginAdmin/Login';
import { Routes, Route, useLocation } from "react-router-dom"

function App() {
    const [selectedLanguageLeft, setSelectedLanguageLeft] = useState('');
    const [selectedLanguageRight, setSelectedLanguageRight] = useState('');
    const [searchedWord, setSearchedWord] = useState('');
    const isEnglishOptionVisible = false;

    const handleSelectLanguageLeft = (lang) => {
        setSelectedLanguageLeft(lang);
    };

    const handleSelectLanguageRight = (lang) => {
        setSelectedLanguageRight(lang);
    };

    const handleSearch = (word) => {
        setSearchedWord(word);
    };

    // Get the current location using the useLocation hook
    const location = useLocation();

    useEffect(() => {
        // Reset state values when navigating away from the home page
        if (location.pathname !== '/') {
            setSelectedLanguageLeft('');
            setSelectedLanguageRight('');
            setSearchedWord('');
        }
    }, [location]);

    return (
        <div className={"App"}>
            <Title />
            <NavigationBar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<AboutUs />} />Ï
                <Route path="/adminpage" element={<AdminHomepage/>}/>
                <Route path="/adminpage/edit" element={<AdminEditPage/>}/>
                <Route path="/adminpage/add" element={<AdminAdd/>}/>
                <Route path="/adminpage/view" element={<AdminViewDictionary/>}/>
                <Route path="/Login" element={<Login/>}/>
            </Routes>
            {/* Conditionally render the SplitScreen component */}
            {location.pathname === '/' && (
                <SplitScreen
                    topContent={
                        <div>
                            {/* Add content for the top panel */}
                            <SearchWordComponent onSearch={handleSearch} />
                        </div>
                    }
                    leftContent={
                        <div>
                            {/* Add content for the left panel */}
                            <LanguageSelector onSelectLanguage={handleSelectLanguageLeft} isEnglishOptionVisible={isEnglishOptionVisible}/>
                            <TranslationResultComponent
                                selectedLanguage={selectedLanguageLeft}
                                searchedWord={searchedWord}
                            />
                        </div>
                    }
                    rightContent={
                        <div>
                            {/* Add content for the right panel */}
                            <LanguageSelector onSelectLanguage={handleSelectLanguageRight} isEnglishOptionVisible={isEnglishOptionVisible} />
                            <TranslationResultComponent
                                selectedLanguage={selectedLanguageRight}
                                searchedWord={searchedWord}
                            />

                            <AdminLoginButton/>
                        </div>
                    }
                />
            )}
        </div>
    );
}
export default App;