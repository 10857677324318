import React from "react";
import './About.css'
//individual Page About us. Would be clicked on through navbar + routing mechanism.
export default function AboutUs() {
  return (
    <div className="container">
      <h2>About South Asian Multilingual Dictionary</h2>
      <p>
        Welcome to the South Asian Multilingual Dictionary, your comprehensive online
        resource for exploring languages and cultures of South Asia. Our mission
        is to provide accurate and accessible language tools that enable users
        to discover and understand a rich variety of languages, including
        Bengali, Hindi, Persian, Punjabi, Urdu, and Tamil.
      </p>
      <p>
        At South Asian Multilingual Dictionary, we are committed to delivering a seamless
        and user-friendly experience. Our platform empowers users to explore
        word meanings, translations, pronunciations, and example sentences
        across multiple languages. Whether you're a language enthusiast, a
        student, or a traveler, our diverse and extensive database is here to
        assist you on your language journey.
      </p>
      <p>
        Our dedicated team of language experts, software engineers, and
        designers work tirelessly to ensure the accuracy and reliability of our
        content. We continuously strive to improve our platform, enhance user
        interactions, and incorporate valuable feedback from our community.
      </p>
      <p>
        Thank you for joining us on this linguistic adventure. We invite you to
        explore, learn, and connect through the beauty of languages with
        South Asian Multilingual Dictionary.
      </p>
      <p>
        Contact us at{" "}
        <a href="mailto:southasiandictionary@umich.edu">
          southasiandictionary@umich.edu
        </a>{" "}
        for any inquiries or suggestions.
      </p>
    </div>
  );
}
