export class DictionaryResult {
    constructor(
        word_id,
        translated_word,
        word_class,
        pronunciation,
        synonym,
        usage_sentence,
        audio_file,
        english_word,
    ) {
        this.word_id = word_id;
        this.translated_word = translated_word;
        this.word_class = word_class;
        this.pronunciation = pronunciation;
        this.synonym = synonym;
        this.usage_sentence = usage_sentence;
        this.audio_file = audio_file;
        this.english_word = english_word;
    }
}

export class EnglishWordResult {
    constructor(
        word_id,
        english_word,
        bengali_word,
        hindi_word,
        persian_word,
        punjabi_word,
        tamil_word,
        urdu_word,
    ) {
        this.word_id = word_id;
        this.english_word = english_word;
        this.bengali_word = bengali_word;
        this.hindi_word = hindi_word;
        this.persian_word = persian_word;
        this.punjabi_word = punjabi_word;
        this.tamil_word = tamil_word;
        this.urdu_word = urdu_word;
    }
}