import React, { useState } from 'react';
import './AdminLoginButton.css';
import { useNavigate } from "react-router-dom";


const AdminLoginButton = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState(false);
  const [showForm, setShowForm] = useState(false); // Add this line
  //declared const for access to react router hook navigate. working as of 09/29.
  const navigate = useNavigate();


  const handleLogin = (e) => {
    e.preventDefault();

    // Replace this with actual authentication logic
    if (username === 'admin' && password === 'admin123') {
      // Successful login logic
      setLoginError(false);
      console.log('Admin logged in');
    } else {
      // Failed login logic
      setLoginError(true);
    }
  };
  const ClickDouble = () => { //set two event handlers in one
    setShowForm(true);
    navigate("/Login");
  }
  //onClickCapture={()=> navigate("/Login")}
  return (
    
    <div className="admin-login">
      <button onClick={ClickDouble} >Admin Login</button> {/* Modify this line */}
      {showForm && ( // Add this line
        <form onSubmit={handleLogin}>
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          {loginError && <p className="error-message">Invalid username or password.</p>}
          <button type="submit" onClick={() => navigate("/adminpage")}>Submit</button>
        </form>
      )} {/* Add this line */}
    </div>
  );
};

export default AdminLoginButton;

{/*        <div className="form-group">
          <label htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        {loginError && <p className="error-message">Invalid username or password.</p>}*/}
      
     