import React, { useState } from 'react';
import './DeletePopUp.css'; // Link to the CSS file
import axios from "axios";

function displaySelectedLanguageAsWholeName(selectedLanguage) {
    switch (selectedLanguage) {
        case "BEN":
            return "Bengali"
        case "HIN":
            return "Hindi";
        case "URD":
            return "Urdu";
        case "PER":
            return "Persian";
        case "PAN":
            return "Punjabi";
        case "TAM":
            return "Tamil";
        default:
            return ""
    }
}

function AddNewWordToSelectedLanguage({newWordLanguage, RefreshViewTable, onCancel, newWordId, currentEnglishWord }) {
    const [newWord, setNewWord] = useState("");

    const handleNewWordChange = (e) => {
        setNewWord(e.target.value);
    };
    const handleAddNewWord = () => {
        axios.post(`https://southasiandictionary.asian.lsa.umich.edu/api/addTranslation?selectedLanguage=${newWordLanguage}&word_id=${newWordId}`, {
            new_translation: newWord,
        })
            .then(response => {
                RefreshViewTable();
                onCancel();
            })
            .catch(error => {
                console.error('Failed to add new word:', error);
            });
    };

    return (
        <div className={`add-new-word-modal`}>
            <div className="popup-content">
                <h3>Add {displaySelectedLanguageAsWholeName(newWordLanguage)} Translation for {currentEnglishWord}</h3>
                <div>
                    <label htmlFor="newWord">Translation: </label>
                    <input
                        type="text"
                        id="newWord"
                        value={newWord}
                        onChange={handleNewWordChange}
                    />
                </div>
                <div className="button-container">
                    <button className="cancel-button" onClick={onCancel}>Cancel</button>
                    <button className="add-button" onClick={handleAddNewWord}>Add</button>
                </div>
            </div>
        </div>
    );
}

export default AddNewWordToSelectedLanguage;
