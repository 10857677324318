import React, { useState } from 'react';
import './DeletePopUp.css'; // Link to the CSS file
import axios from "axios";

function AddNewWordModal({selectedLanguage, RefreshViewTable, onCancel }) {
    const [newWord, setNewWord] = useState("");

    const handleNewWordChange = (e) => {
        setNewWord(e.target.value);
    };
    const handleAddNewWord = () => {
        // Make your API call to add a new word here
        axios.post(`https://southasiandictionary.asian.lsa.umich.edu/api/addNewRow?selectedLanguage=${selectedLanguage}`, {
            englishWord: newWord,
        })
            .then(response => {
                RefreshViewTable();
                onCancel();
            })
            .catch(error => {
                console.error('Failed to add new word:', error);
            });
    };

    return (
        <div className={`add-new-word-modal`}>
            <div className="popup-content">
                <h3>Add New Word</h3>
                <div>
                    <label htmlFor="newWord">New Word:</label>
                    <input
                        type="text"
                        id="newWord"
                        value={newWord}
                        onChange={handleNewWordChange}
                    />
                </div>
                <div className="button-container">
                    <button className="cancel-button" onClick={onCancel}>Cancel</button>
                    <button className="add-button" onClick={handleAddNewWord}>Add</button>
                </div>
            </div>
        </div>
    );
}

export default AddNewWordModal;
