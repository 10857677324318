// AdminEditPage.js
import React, { useState } from 'react';
import './AdminEditPage.css';

const AdminEditPage = () => {
  const [word, setWord] = useState('');
  const [conjugation, setConjugation] = useState('');
  const [synonym, setSynonym] = useState('');
  const [example, setExample] = useState('');
  const [image, setImage] = useState('');
  const [audioFile, setAudioFile] = useState('');

  const handleImageChange = (e) => {
    // Handle file upload logic here
    // You can use FileReader to preview the image before uploading
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleAudioFileChange = (e) => {
    // Handle audio file upload logic here
    const file = e.target.files[0];
    setAudioFile(file.name);
  };

  return (
    <div className="admin-edit-page">
      <h1>Edit Dictionary Entry</h1>
      <div className="form">
        <label htmlFor="word">Word:</label>
        <input
          type="text"
          id="word"
          value={word}
          onChange={(e) => setWord(e.target.value)}
          placeholder="Type here..."
        />

        <label htmlFor="conjugation">Conjugation:</label>
        <input
          type="text"
          id="conjugation"
          value={conjugation}
          onChange={(e) => setConjugation(e.target.value)}
          placeholder="Type here..."
        />

        <label htmlFor="synonym">Synonym:</label>
        <input
          type="text"
          id="synonym"
          value={synonym}
          onChange={(e) => setSynonym(e.target.value)}
          placeholder="Type here..."
        />

        <label htmlFor="example">Example:</label>
        <input
          type="text"
          id="example"
          value={example}
          onChange={(e) => setExample(e.target.value)}
          placeholder="Type here..."
        />

        <label htmlFor="image">Image:</label>
        <input
          type="file"
          id="image"
          accept="image/*"
          onChange={handleImageChange}
        />
        {image && <img src={image} alt="Preview" className="image-preview" />}

        <label htmlFor="audioFile">Audio File:</label>
        <input
          type="file"
          id="audioFile"
          accept="audio/*"
          onChange={handleAudioFileChange}
        />
        {audioFile && <p>{audioFile}</p>}
      </div>
    </div>
  );
};

export default AdminEditPage;
