export function displaySelectedLanguageAsWholeName(selectedLanguage, setDisplayLanguage) {
    switch (selectedLanguage) {
        case "BEN":
            setDisplayLanguage("Bengali");
            break;
        case "ENG":
            setDisplayLanguage("English");
            break;
        case "HIN":
            setDisplayLanguage("Hindi");
            break;
        case "URD":
            setDisplayLanguage("Urdu");
            break;
        case "PER":
            setDisplayLanguage("Persian");
            break;
        case "PAN":
            setDisplayLanguage("Punjabi");
            break;
        case "TAM":
            setDisplayLanguage("Tamil");
            break;
        default:
            setDisplayLanguage(""); // Default to empty if the language code is not recognized
    }
}