import React from 'react'; 
import './NavigationBar.css';
import './NavigationBar';
import {NavLink as Link} from "react-router-dom"
class NavigationBar extends React.Component {
   handleClick = (event) => {
    event.preventDefault();
    // Handle navigation logic here 
    console.log('Clicked: ${event.target.textContent}');
}    

render() {     
    return (
        <div className="NavigationBar">
            <nav className="navbar">           
                <Link to="/" className="nav-item" >Home</Link>                     
                <Link to="/about" className="nav-item" >About</Link>         
            </nav>         
            <div className="content">           
                {/* page content goes here */}         
            </div>       
        </div>     
        );   
    } 
}  

export default NavigationBar;