import React, { useState } from 'react';
import './DeletePopUp.css'; // Link to the CSS file
import axios from "axios";

function displaySelectedLanguageAsWholeName(selectedLanguage) {
    switch (selectedLanguage) {
        case "BEN":
            return "Bengali"
        case "HIN":
            return "Hindi";
        case "URD":
            return "Urdu";
        case "PER":
            return "Persian";
        case "PAN":
            return "Punjabi";
        case "TAM":
            return "Tamil";
        default:
            return ""
    }
}

function displaySelectedAttributeForReadability(attribute) {
    switch (attribute) {
        case "word_class":
            return "Word Class"
        case "pronunciation":
            return "Pronunciation";
        case "synonym":
            return "Synonym";
        case "usage_sentence":
            return "Usage Sentence";
        default:
            return attribute
    }
}

function UpdateWordWithSelectedAttributeInSelectedLanguageModal({updatedAttributeLanguage, selectedAttribute, RefreshViewTable, onCancel, newWordId, currentEnglishWord, originalWord}) {
    const [updatedAttributeText, setUpdatedAttributeText] = useState("");

    const handleUpdateAttribute = (e) => {
        setUpdatedAttributeText(e.target.value);
    };
    const handleUpdateWordAttribute = () => {
        axios.post(`https://southasiandictionary.asian.lsa.umich.edu/api/updateWordAttributes?selectedLanguage=${updatedAttributeLanguage}&word_id=${newWordId}`, {
            selected_attribute: selectedAttribute,
            updated_attribute_text: updatedAttributeText
        })
            .then(response => {
                RefreshViewTable();
                onCancel();
            })
            .catch(error => {
                console.error('Failed: ', error);
            });
    };

    return (
        <div className={`add-new-word-modal`}>
            <div className="popup-content">
                <h3>Add {displaySelectedAttributeForReadability(selectedAttribute)} for {originalWord} ({currentEnglishWord}) in {displaySelectedLanguageAsWholeName(updatedAttributeLanguage)}</h3>
                <div>
                    <label htmlFor="updated_attribute">{displaySelectedAttributeForReadability(selectedAttribute)}: </label>
                    <input
                        type="text"
                        id="updated_attribute"
                        value={updatedAttributeText}
                        onChange={handleUpdateAttribute}
                    />
                </div>
                <div className="button-container">
                    <button className="cancel-button" onClick={onCancel}>Cancel</button>
                    <button className="add-button" onClick={handleUpdateWordAttribute}>Add</button>
                </div>
            </div>
        </div>
    );
}

export default UpdateWordWithSelectedAttributeInSelectedLanguageModal;
