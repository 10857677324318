import React, { useState } from 'react';
import './SearchWordComponent.css'; // Import the CSS file

function SearchWordComponent({ onSearch }) {
    // State to hold the input value
    const [searchedWord, setSearchedWord] = useState('');

    // Handle the search button click event
    const handleSearch = () => {
        if (searchedWord.trim() !== '') {
            onSearch(searchedWord);
        }
    };

    // Handle the Enter key press event
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <div className="search-container">
            {/* Search input */}
            <input
                type="text"
                placeholder="Enter a word"
                value={searchedWord}
                onChange={(e) => setSearchedWord(e.target.value)}
                onKeyDown={handleKeyDown}
            />
            {/* Search button */}
            <button className="search-button" onClick={handleSearch}>Search</button>
        </div>
    );
}

export default SearchWordComponent;
